<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="cohortSummary === undefined">
      <h4 class="alert-heading">Error fetching cohort data</h4>
      <div class="alert-body">
        No cohort found with this cohort id. Check
        <b-link class="alert-link" :to="{ name: 'apps-accounting-receivable-cohort-list' }"> Cohort List </b-link>
        for other cohorts.
      </div>
    </b-alert>

    <b-row>
      <b-col md="12" lg="7">
        <cohort-statistics :cohort-summary="cohortSummary" type="s1"></cohort-statistics>
      </b-col>
      <b-col md="12" lg="5">
        <cohort-statistics :cohort-summary="cohortSummary" type="s2"></cohort-statistics>
      </b-col>
    </b-row>

    <!-- First Row -->
    <b-row>
      <b-col cols="12" xl="3" lg="3" md="12">
        <cohort-view-cohort-info-card :cohort-summary="cohortSummary" />
      </b-col>
      <b-col cols="12" md="12" xl="9" lg="9">
        <b-card no-body class="mb-0">
          <cohort-account-list @refresh="loadCohortSummary" :cohort-data="cohortSummary.cohort"></cohort-account-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import receivableStoreModule from '../receivableStoreModule';
import CohortViewCohortInfoCard from './CohortViewUserInfoCard';
import useCohortAccountsList from './useCohortAccountsList';
import CohortAccountList from '@/views/apps/accounting/receivable/cohort-view/CohortAccountList';
import CohortStatistics from '@/views/apps/accounting/receivable/cohort-view/CohortStatistics';

export default {
  components: {
    CohortStatistics,
    CohortAccountList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,

    // Local Components
    CohortViewCohortInfoCard,
  },
  methods: {
    loadCohortSummary() {
      this.$store
        .dispatch('app-accounting-receivable/fetchCohortSummary', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          this.cohortSummary = response.data;
          console.log(`cohort`, response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.cohortSummary = undefined;
          }
        });
    },
  },
  mounted() {
    this.loadCohortSummary();
  },
  setup() {
    const cohortSummary = ref({ cohort: {}, meta: {} });

    const COHORT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    const {
      fetchSessions,
      tableColumns,
      perPage,
      currentPage,
      totalSessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSessionListTable,
      refetchData,

      statusOptions,

      // UI
      resolveSessionVisibilityVariant,
      resolveSessionStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useCohortAccountsList(cohortSummary);

    return {
      cohortSummary,

      fetchSessions,
      tableColumns,
      perPage,
      currentPage,
      totalSessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSessionListTable,
      refetchData,

      statusOptions,

      // UI
      resolveSessionVisibilityVariant,
      resolveSessionStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    };
  },
};
</script>

<style></style>
