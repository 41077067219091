<template>
  <b-modal
    id="modal-lg"
    ok-title="Add to Cohort"
    centered
    size="lg"
    title="Search for accounts"
    ref="modal"
    @hidden="() => $emit('update:is-add-account-open', false)"
    :visible="isAddAccountOpen"
    :hide-footer="true"
  >
    <b-card no-body>
      <b-row>
        <b-col cols="12" lg="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalResults"
            :per-page="perPage"
            aria-controls="table-test"
          ></b-pagination>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group class="justify-content-end">
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        id="table-test"
        :busy.sync="isBusy"
        :items="accountProvider"
        :fields="accountFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchTerm"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(dates)="data">
          {{ classesToRange(data.item.classes) }}
        </template>

        <template #cell(status)="data">
          {{ title(data.item.status) }}
        </template>

        <template #cell(visibility)="data">
          {{ title(data.item.visibility) }}
        </template>

        <template #cell(actions)="data">
          <b-button @click="assignAccount(data.item)" variant="primary" size="sm">Select</b-button>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 0,
      totalResults: 0,

      accountFields: [
        { key: 'name', sortable: true },
        { key: 'dates' },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      sortBy: 'createdAt',
      sortDesc: true,

      account: undefined,
    };
  },
  props: {
    isAddAccountOpen: {
      type: Boolean,
    },
    cohortData: {
      type: Object,
    },
  },
  computed: {
    cohort() {
      return this.cohortData;
    },
  },
  methods: {
    classesToRange(classes) {
      if (!classes || classes.length === 0) return 'n/a';
      let dates = classes.map((clazz) => clazz.date).sort((a, b) => a - b);

      return [
        moment(dates[0]).utc().format('MMM D'),
        moment(dates[dates.length - 1])
          .utc()
          .format('MMM D'),
      ].join(' - ');
    },
    accountProvider(ctx) {
      const promise = store.dispatch('app-roster/fetchAccounts', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
        populate: 'student profile',

        populate: 'classes',
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching account list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    assignAccount(account) {
      this.account = account;

      store
        .dispatch('app-roster/addAccountsToCohort', {
          cohortId: this.cohort.id,
          accountIds: [this.account.id],
        })
        .then((response) => {
          const { data: cohort } = response;

          if (!cohort.accounts.includes(this.account.id)) {
            return this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'XIcon',
                variant: 'error',

                title: 'Failed to add to account',
                text: 'This account is already assigned to this cohort',
              },
            });
          }

          this.$emit('update:is-add-account-open', false);
          this.$emit('refresh', true);

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added account to cohort',
              text: 'This account has been assigned to this cohort',
            },
          });
        })
        .catch((err) => {
          console.error(`failed to add account to cohort`, err);
        });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>
