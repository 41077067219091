<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="mr-25 mb-0"> Updated <timeago :datetime="new Date()" :auto-update="60" /> </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col v-for="item in statisticsItems" :key="item.subtitle" class="mb-2 mb-md-0" :class="item.customClass">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon v-if="item.icon" size="24" :icon="item.icon" />
                <font-awesome-icon :v-else-if="item.faIcon" :icon="item.faIcon" size="lg"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue';

export default {
  name: 'CohortStatistics',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    cohortSummary: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    cohort() {
      if (!this.cohortSummary.cohort) return {};
      return this.cohortSummary.cohort;
    },
    statisticsItems() {
      if (this.type === 's1') {
        return [
          {
            icon: 'DollarSignIcon',
            color: 'light-info',
            title: this.money(this.cohortSummary.meta.amountTotal || 0),
            subtitle: 'Total Due',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: this.money(this.cohortSummary.meta.paidTotal || 0),
            subtitle: 'Total Paid',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: this.money(this.cohortSummary.meta.balanceTotal || 0),
            subtitle: 'Balance',
            customClass: '',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-danger',
            title: this.money(this.cohortSummary.meta.pastDueTotal || 0),
            subtitle: 'Total Past Due',
            customClass: '',
          },
        ];
      } else if (this.type === 's2') {
        return [
          {
            faIcon: 'fa-solid fa-cash-register',
            color: 'light-info',
            title: this.cohortSummary.meta.totalCharges || 0,
            subtitle: 'Charges',
            customClass: '',
          },
          {
            faIcon: 'fa-solid fa-receipt',
            color: 'light-success',
            title: this.cohortSummary.meta.totalPayments || 0,
            subtitle: 'Payments',
            customClass: '',
          },
          {
            faIcon: 'fa-solid fa-receipt',
            color: 'light-danger',
            title: this.cohortSummary.meta.totalPastDuePayments || 0,
            subtitle: 'Past Due',
            customClass: '',
          },
        ];
      } else {
        return [];
      }
    },
  },
  data: () => {
    return {};
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
  },
};
</script>

<style scoped></style>
