import { ref, watch, computed, onUnmounted } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import moment from 'moment';
import store from '@/store';
import receivableStoreModule from '@/views/apps/accounting/receivable/receivableStoreModule';

export default function useCohortAccountsList(cohortData) {
  // Use toast
  const toast = useToast();

  const RECEIVABLE_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

  // Register module
  if (!store.hasModule(RECEIVABLE_APP_STORE_MODULE_NAME))
    store.registerModule(RECEIVABLE_APP_STORE_MODULE_NAME, receivableStoreModule);

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(RECEIVABLE_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIVABLE_APP_STORE_MODULE_NAME);
  });

  const refAccountListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'student.fullName', label: 'Name', sortable: true, stickyColumn: true, variant: 'primary' },
    { key: 'total', label: 'Debit', sortable: true },
    { key: 'totalPaid', label: 'Credit', sortable: true },
    { key: 'balance', label: 'Balance', sortable: true },
    { key: 'profile.attributes.fundingSource', label: 'Funding', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalAccounts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('createdAt');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAccountListTable.value ? refAccountListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAccounts.value,
    };
  });

  const refetchData = () => {
    if (refAccountListTable.value) {
      refAccountListTable.value.refresh();
    }
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, cohortData], () => {
    refetchData();
  });

  const fetchAccounts = (ctx, callback) => {
    const cohort = cohortData.value;

    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      cohort: cohort.id,

      populate: 'student,profile,cohort',
    };

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('app-accounting-receivable/fetchAccounts', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalAccounts.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cohorts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  return {
    fetchAccounts,
    tableColumns,
    perPage,
    currentPage,
    totalAccounts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAccountListTable,

    refetchData,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
